import React from 'react';
import style from './blog-post.module.sass';
import layoutStyle from "../layout/layout.module.sass"
import Layout from "../layout/layout"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, } from '@contentful/rich-text-types';
import { Link } from 'gatsby'

function BlogPost(props) {

    let options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const imageUrl = node.data.target.fields
                if(imageUrl !== undefined) {
                    return `<img class="${style.blogImage}" src="${imageUrl.file["en-US"].url}"/>`
                }
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                console.log(node.content[0].value)
                    return `<h2 class="${style.subHeader}">${node.content[0].value}</h2>`
                }
            }
        }

    const body = documentToHtmlString(JSON.parse(props.pathContext.body.body), options)

    return (
        <Layout>
            <div className={layoutStyle.pageContainerWithMargin}>
                <div className={style.postContainer}>
                    <img src={props.pathContext.image.file.url} alt=""/>
                    <div className={style.postBody}>
                        <h1>{props.pathContext.title}</h1>
                        <p className={style.author}>Written by: {props.pathContext.author}</p>
                        <p className={style.preamble}>{props.pathContext.preamble.preamble}</p>
                        <div dangerouslySetInnerHTML={{__html: body}}/>
                    </div>
                    <Link to="/blog/">
                        <button className={style.backButton} >Back to blog overview</button>
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
export default BlogPost